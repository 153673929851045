import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

import { vw, paths, createOptions, genID } from '../utilities/helpers'
import SEO from '../utilities/seo'
import { countryList, districtList } from '../utilities/data'
import photo from '../assets/images/placeholders/cut-a.png'

import Block from '../components/block'
import Bit from '../components/bit'
import Glow from '../components/glow'
import Preface from '../components/preface'
import Next from '../components/next'
import Parallax from '../components/parallax'
import Button from '../components/button'
import Form from '../components/form'
import Hero from '../components/hero'
import Footer from '../components/footer'
import Text from '../components/text'

const id = genID()

const Wrap = styled.section`
    position: relative; 
    margin-left: ${vw(3)};
    width: ${vw(17)};

    h1{
        padding-bottom: ${vw(1)};

        span:first-child{
          padding-bottom: ${vw(0.125)}
        }
    }

    @media (max-width: 1023px){
        width: 90%;
        margin-left: 5%;
    }

    @media (max-width: 767px){
        width: 80%;
        margin: 0 auto;

        h1 {
            padding-bottom: 48px;
        }
    }
`

// const collection = []

// function renderItems(items, index) {
//     var node = items.node.data

//     collection[index] = {
//         'key': index,
//         'title': node.name.text,
//         'subtitle': node.subtitle.text,
//         'bg': patterns.green,
//         'fg': node.image.url,
//         'website': node.website.url,
//         'bio': node.bio.html
//     }
// }

function renderPreface(content, index) {
    const item = content.node.data

    return (
        <Block key={index} className="page-preface">
            <svg viewBox="0 0 1320 720" className="page-preface__image">
                <defs>
                    <linearGradient id={id+'f'} gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                        <stop offset="0" stopColor="#fff" />
                        <stop offset="1" stopColor="#000" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id={id+'g'} gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                        <stop offset="0" stopColor="#4744E5" />
                        <stop offset="1" stopColor="#000" stopOpacity="0" />
                    </linearGradient>
                    <mask id={id+'m'}>
                        <path fill={'url(#'+id+'f)'}x="100px" d={paths.a} />
                    </mask>
                </defs>
                <g mask={'url(#'+id+'m)'}>
                    <image xlinkHref={item.image.url} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice"></image>
                </g>
            </svg>
            <Preface
                title={item.heading.text}

                summary={item.preface.text}

                meta={[
                    {
                        'key': 1,
                        'type': 'location',
                        'label': item.venue.text
                    },
                    {
                        'key': 2,
                        'type': 'time',
                        'label': item.timestamp
                    }
                ]}
            />

            <Glow royal top="-15%" left={vw(-4)} delay="0" />
            <Bit ring top="25%" right="25%" />
            <Bit arrow top="35%" right="10%" />

            <Glow warm bottom="-50%" right={vw(-4)} delay="0.75" />
            <Bit zig top="55%" right="35%" />
        </Block>
    )
}

export default ({data}) => {
    return (
      <>
      <main>    
        <SEO description={data.allPrismicPage.edges[0].node.data.preface.text} title={data.allPrismicPage.edges[0].node.data.label.text} image={data.allPrismicPage.edges[0].node.data.image.url} />

        {data.allPrismicPage.edges.map(renderPreface)}

        {/* <Block flex='true'>
            <Glow royal top="-5%" left={vw(-4)} delay="0" />
            <Bit cross top="35%" left="12%" />

            <Glow cool top="15%" right={vw(-2)} delay="0.75" />
            <Bit cross top="15%" right="15%" />

            <Glow warm top="20%" left={vw(-6)} delay="1" />
            <Bit arrow top="75%" left="25%" />

            <Glow warm bottom="-5%" right={vw(-4)} delay="1.25" />
            <Bit ring top="100%" right="15%" />

            <Body>
                {data.allPrismicExhibitor.edges.map(renderItems)}
                
                <Grid col={4} cells={collection}/>
            </Body>
        </Block> */}

        {/* 
        <Block>
          <Glow royal top="-15%" left={vw(-4)} delay="0" />
          <Bit arrow top="25%" right="15%" />
          <Bit arrow top="45%" right="15%" />

          <Glow warm bottom="-50%" right={vw(-4)} delay="0.75" />
          <Bit ring top="55%" right="35%" />

          <Wrap>
            <Hero right="0" top="0">
              <img src={photo} alt="Intergenerational Dialogue" />
            </Hero>
            <h1>
              <Text xlarge>Registr to exhibit</Text>
              <Text small>* Field required</Text>
            </h1>
             
            <Form>
              <form method="post" name="exhibit" data-netlify-honeypot="bot-catcher" data-netlify="true" action="/thank-you">
                  <input type="hidden" name="bot-catcher" />
                  <input type="hidden" name="form-name" value="exhibit" />
                <section>
                  <div>
                    <label><Text tiny>First Name*</Text></label>
                    <input type="text" name="firstname" required />
                  </div>
                </section>
    
                <section>
                  <div>
                    <label><Text tiny>Last Name*</Text></label>
                    <input type="text" name="lastname" required />
                  </div>
                </section>

                <section>
                  <div>
                    <label><Text tiny>Position*</Text></label>
                    <input type="text" name="position" />
                  </div>
                </section>
                
                <section>
                  <div>
                    <label><Text tiny>Email*</Text></label>
                    <input type="email" name="email" required />
                  </div>
                </section>

                <section>
                  <div>
                    <label><Text tiny>Phone Number*</Text></label>
                    <input type="text" name="phone number" required />
                  </div>
                </section>
                <section>
                  <div>
                    <label><Text tiny>Organization/School*</Text></label>
                    <input type="text" name="organization" />
                  </div>
                </section>
                <section>
                  <div>
                    <label><Text tiny>Country*</Text></label>
                    <select name="country" defaultValue="Uganda">
                      {countryList.map(createOptions)}
                    </select>
                  </div>
                </section>
                <section>
                  <div>
                    <label><Text tiny>District</Text></label>
                    <select name="district" defaultValue="-">
                      {districtList.map(createOptions)}
                    </select>
                  </div>
                </section>
                <section className="full">
                  <div>
                    <label><Text tiny>Describe your exhibition material and setup needs</Text></label>
                    <textarea name="description" cols="8" required></textarea>
                  </div>
                </section>
                <section className="full">
                  <Button to="/" label="Register" button></Button>
                </section>
                <section className="full gdpr">
                  <p><Text small>Reach a Hand reserves the right to accept an exhibitor. By clicking submit, you agree to the <Link to="/privacy-policy">Terms of Use and Privacy policy</Link> of IGD</Text></p>
                </section>
              </form>
            </Form>
          
          </Wrap>
        </Block>
        */}

        <Next to="/media" title="Media"/>
        
        <Parallax d/>
      </main>
      <Footer />
      </>
    )
}

export const query = graphql`
query Exhibitors {
  allPrismicPage(filter: {uid: {eq: "exhibition"}}) {
    edges {
      node {
        data {
          preface {
            text
          }
          image {
            url
          }
          heading {
            text
          }
          label {
            text
          }
          timestamp(formatString: "dddd DD MMMM YYYY HH:ma")
          venue {
            text
          }
        }
        uid
      }
    }
  }
  allPrismicExhibitor {
    edges {
      node {
        data {
          bio {
            html
          }
          image {
            url
          }
          name {
            text
          }
          website {
            url
          }
          subtitle {
              text
          }
        }
      }
    }
  }
}
`